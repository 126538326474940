import { Container, Grid, Button, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import SignupFields from './signupFields'
import './styles.css'
import { FaUserPlus } from 'react-icons/fa'
import { useState } from 'react'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'

function Signup() {
    const [errormessage, setErrorMessage] = useState('')
    return (
        <>
            <Grid container fluid >
                <Grid lg={8} md={12} sm={12} xs={12} className="SignupWrapper">
                    <Box className='SignupContainer'>
                        <div className='SignupCard'>
                            <div className='SignupArrowBack'>
                                <Link to="/">
                                    <BsFillArrowLeftCircleFill size={25} style={{ color: '#a4a4a4' }} />
                                </Link>
                            </div>
                            <div className='SignupContainerChildOne'>
                                <p className='SigninSignupTitle'>
                                    Sign up
                                </p>
                                {
                                    errormessage ?
                                        <p style={{ color: 'green', fontWeight: 'bold', padding: '8px 0px' }}>{errormessage}</p>
                                        :
                                        null
                                }
                                <SignupFields setErrorMessage={setErrorMessage} />
                            </div>
                        </div>
                    </Box>
                </Grid>
                <Grid lg={4} md={12} sm={12} xs={12} className="SignupWrapper">
                    <div className='SignupContainer'>
                        <div className='SignupCard'>
                            <div>
                                <div>
                                    <p className='SigninSignupTitle'>
                                        Already have an account?
                                    </p>
                                </div>
                                <p className='SigninSignup'> Sign in using your username and password
                                    {" "}
                                </p>
                                <Link to="/signin" style={{ textDecoration: 'none', }}>
                                    <Button
                                        variant="outlined"
                                        className="submitButton"
                                    >
                                        Sign in
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Signup