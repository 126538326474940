import { Container, Grid } from '@mui/material';
import React from 'react';
import Cookies from 'universal-cookie';
import Features from '../../sections/features';
import './styles.css';


function Body({ coins }) {
    const cookie = new Cookies()

    return (
        <>
            <div className="bodyWrapper">
                <Container style={{ padding: 0, margin: 0 }}>
                    <Grid container fluid >
                        <Grid item lg={9} md={8} sm={6} xs={12} style={{ padding: 0, margin: 0 }}>
                            <div className='bodyContainer'>
                                <p className='bodyTitle'>Unlock the Future of Finance with vodocrypto: </p>
                                <p className='bodyDescription'>Empowering Secure and Borderless Transactions. </p>
                                {/* {
                                        cookie.get('token') ?
                                            null
                                            :
                                            <div className='buttonsContainer'>
                                                <Link to="/signup" style={{ textDecoration: 'none', color: 'white' }}>
                                                    <Button
                                                        variant="contained"
                                                        className='createAccount'
                                                    >
                                                        Create Account
                                                    </Button>
                                                </Link>

                                            </div>
                                    } */}
                            </div>
                        </Grid>

                        <Grid lg={3} md={4} sm={6} xs={12}>
                            <Features />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default Body