import * as React from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem, Grid } from '@mui/material'
import './styles.css'
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import { AiFillHome } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi'
import Modale from '../modal';
import useViewport from "../../hooks/useViewport";
import { getUserById } from '../../api';



const pages =
    [
        {
            id: 1,
            name: 'Products',
            link: '/signin'
        },
        {
            id: 2,
            name: 'Prices',
            link: '/markets'
        },
        {
            id: 3,
            name: 'Learn',
            link: '/signin'
        },
        {
            id: 4,
            name: 'Help',
            link: '/signin'
        },
        {
            id: 5,
            name: 'Markets',
            link: '/markets',
        }
    ];

const loggedinPage = [
    {
        id: 1,
        name: 'deposit',
        link: '/deposit'
    },
    {
        id: 2,
        name: 'withdraw',
        link: '/withdraw'
    },
    {
        id: 3,
        name: 'market',
        link: '/markets'
    },
    {
        id: 4,
        name: 'transactions',
        link: '/transactions'
    },
    {
        id: 5,
        name: 'my profile',
        link: '/profile'
    },

]

const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const cookie = new Cookies();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const { width } = useViewport();
    const [user, setUser] = useState([])


    useEffect(
        () => {
            cookie.get('id') &&
                getUserById(cookie.get('id')).then(res => {
                    setUser(res.data)
                }).catch(error => console.log(error));
        },
        [])

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const logOutUserMenu = () => {
        setAnchorElUser(null);
        cookie.remove('token');
        navigate("/");
    }


    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <AppBar className='navbarWrapper'>
                {/* {
                    cookie.get('token') ?
                        <div className='homeButton'>
                            <Link to="/" style={{ color: 'black', textDecoration: 'none' }}>
                                <AiFillHome size={25} color="white" />
                            </Link>
                        </div>
                        :
                        null
                } */}

                <Container maxWidth="lg" style={{ padding: 0 }}>
                    <Toolbar disableGutters>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'white',
                                textDecoration: 'none',
                            }}
                        >
                            <div className={(width < 450 ? 'mobileLogo' : 'logo')}></div>
                        </Typography>


                        {
                            cookie.get('token') ?
                                <>
                                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                        {loggedinPage.map((page) => (
                                            <>
                                                <Link to={page.link} style={{ textDecoration: 'none' }} >
                                                    <Button
                                                        key={page.id}
                                                        onClick={handleCloseNavMenu}
                                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                                    >
                                                        {page.name}
                                                    </Button>
                                                </Link>

                                            </>
                                        ))}
                                        <Button
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                            onClick={handleOpenModal}
                                        >
                                            Contact us
                                        </Button>
                                    </Box>
                                    {
                                        width > 400 ?
                                            <>
                                                <Box style={{ marginLeft: 16, color: 'white' }}>
                                                    <h3>{(user?.balance ? user.balance : 0) + " " + cookie.get('currency') + " - " + cookie.get('username')}</h3>
                                                </Box>

                                                <Box sx={{ flexGrow: 0 }} style={{ marginLeft: 8, marginTop: 8, color: 'white', cursor: 'pointer' }}>
                                                    <Typography onClick={logOutUserMenu}><FiLogOut size={25} /></Typography>
                                                </Box>
                                            </>
                                            :
                                            null
                                    }
                                </>
                                :
                                <>
                                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                        {pages.map((page) => (
                                            <Link to={page.link} style={{ textDecoration: 'none' }} >
                                                <Button
                                                    key={page.id}
                                                    onClick={handleCloseNavMenu}
                                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                                >
                                                    {page.name}
                                                </Button>
                                            </Link>
                                        ))}
                                    </Box>
                                    <Box sx={{ display: { xs: 'none', md: 'flex' } }} >
                                        <Link to="/signin" style={{ textDecoration: 'none', color: 'white' }}>
                                            <Button
                                                variant="outlined"
                                                className="signinButton"
                                            >
                                                <b>Sign in</b>
                                            </Button>
                                        </Link>
                                        <Link to="/signup" style={{ textDecoration: 'none', color: 'black' }}>
                                            <Button
                                                variant="contained"
                                                className='signupButton'
                                            >
                                                <b>Sign up</b>
                                            </Button>
                                        </Link>
                                    </Box>
                                </>
                        }


                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <div className='hamburgerMenu'></div>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {
                                    cookie.get('token') ?
                                        <div style={{ padding: 8 }}>
                                            {loggedinPage.map((page) => (
                                                <Link to={page.link} style={{ textDecoration: 'none', color: 'white' }} >
                                                    <Button
                                                        key={page.id}
                                                        onClick={handleCloseNavMenu}
                                                        sx={{ my: 2, color: 'black', display: 'block', textTransform: 'capitalize', margin: 0 }}
                                                    >
                                                        {page.name}
                                                    </Button>
                                                </Link>
                                            ))}
                                            <Button
                                                sx={{ my: 2, color: 'black', display: 'block', textTransform: 'capitalize', margin: 0 }}
                                            >
                                                Contact us
                                            </Button>
                                            {
                                                width < 400 ?
                                                    <>
                                                        <Box style={{ color: 'black', fontSize: 14 }}>
                                                            <h3>{(user?.balance ? user.balance : 0) + " " + cookie.get('currency') + " - " + cookie.get('username')}</h3>
                                                        </Box>

                                                        <Box sx={{ flexGrow: 0 }} style={{ marginLeft: 8, marginTop: 8, color: 'black', cursor: 'pointer' }}>
                                                            <Typography onClick={logOutUserMenu}>Log Out</Typography>
                                                        </Box>
                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>
                                        :
                                        <div style={{ padding: 8 }}>
                                            {pages.map((page) => (
                                                <Link to={page.link} style={{ textDecoration: 'none' }} key={page.id}>
                                                    <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                                                        <Typography textAlign="center" style={{ color: 'black' }}>{page.name}</Typography>
                                                    </MenuItem>
                                                </Link>
                                            ))}
                                            <Button
                                                variant="outlined"
                                                className='signinButton2'

                                            >
                                                <Link to="/signin" style={{ textDecoration: 'none', color: 'black' }}>Sign in</Link>
                                            </Button>
                                            <br></br>
                                            <Button
                                                variant="contained"
                                                // style={{
                                                //     width: 84.6, color: 'black', borderColor: "black", backgroundColor: '#D97201', marginLeft: 8, marginTop: 8, borderRadius: 0, textTransform: 'capitalize', boxShadow: '0px 0px 0px',
                                                // }}
                                                className='signupButton'
                                            >
                                                <Link to="/signup" style={{ textDecoration: 'none', color: 'white' }} >Sign up</Link>
                                            </Button>
                                        </div>
                                }
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Modale handleCloseModal={handleCloseModal} openModal={openModal} />
        </Grid >

    );
};
export default Navbar;
