import { Container, Grid } from '@mui/material'
import React from 'react'
import './style.css'
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/crypto-coins.json'

function MobileApp() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            <div className='cryptocurrencyWrapper'>
                <Container fluid >
                    <Grid container fluid >
                        <Grid container fluid lg={7} md={6} sm={12} xs={12} >
                            <div className='cryptoNftWrapper'>
                                <div className='cryptoNftContainer'>
                                    <div className='cryptoNftTitleContainer'>
                                        <p>Crypto & NFT</p>
                                    </div>
                                    <div className='cryptoContainer'>
                                        <div className='cryptoTitleContainer'>
                                            <p>Cryptocurrency:</p>
                                        </div>
                                        <div className="cryptoParag">
                                            <p>
                                                A cryptocurrency is a digital or virtual currency that uses cryptography to secure and verify
                                                transactions and to control the creation of new units. Cryptocurrencies operate independently
                                                of central banks and are typically decentralized, meaning that they are not controlled by any
                                                single entity or government. The most well-known example of a cryptocurrency is Bitcoin, but
                                                there are many others such as Ethereum, Litecoin, and Dogecoin.
                                            </p>
                                        </div>

                                    </div>
                                    <div className='nftContainer'>
                                        <div className='nftTitleContainer'>
                                            <p>NFT:</p>
                                        </div>
                                        <div className="nftParag">
                                            <p>
                                                NFT stands for non-fungible token, which is a type of digital asset that is unique and cannot be
                                                replicated. NFTs are often used to represent digital artwork, music, and other types of creative works.
                                                Each NFT is created on a blockchain, which is a decentralized and transparent digital ledger that records
                                                the ownership and transaction history of the asset. Unlike cryptocurrencies, which are fungible and
                                                interchangeable, NFTs are one-of-a-kind and their value is largely determined by their rarity and uniqueness.
                                            </p>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </Grid>
                        <Grid container fluid lg={5} md={6} sm={12} xs={12} sx={{ display: { lg: 'block', md: 'block', sm: 'block', xs: 'block' } }}>
                            <div className='cryptocurrencyLottieContainer'>
                                <div className='lottieFileCont'>
                                    <Lottie
                                        options={defaultOptions}
                                        className='lottieFile'
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default MobileApp