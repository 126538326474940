import { Button, Container, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './styles.css'
import { editUser, getUserById } from '../../api'
import Cookies from 'universal-cookie';
import moment from 'moment';
import { useFormik } from 'formik';
import Snackbars from '../../components/snackbar';

function Profile() {
    const cookie = new Cookies()
    const [user, setUser] = useState([])
    const [successopen, setSuccessOpen] = useState(false);
    const [pass, setPass] = useState('');
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
    };

    useEffect(() => {
        const user = getUserById(cookie.get('id'))
        user.then(response => {
            setUser(response.data)
            setPass(response.data.password)
        })
    }, [])


    // const formik = useFormik({
    //     initialValues: {
    //         password: pass,
    //     },
    //     onSubmit: () => { }
    // });
    const editPassword = () => {
        if (user) {
            const data = {
                password: pass,
            }

            const updateUser = editUser(user._id, data)
            updateUser.then(response => {
                if (response.success) {
                    setSuccessOpen(true);
                } else {
                    alert(response.message)
                }
            })
        }

    }

    return (
        <Container >
            <Grid container fluid >
                <Grid lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', alignItems: 'cetner', justifyContent: 'center', color: '#f724c9' }}>
                    <div className='profileWrapper'>
                        <div className='profileContainer'>
                            <div className='profileTitleContainer'>
                                <p className='profileTitle'>Profile</p>
                            </div>
                            <div className='inputContainer'>
                                <div className='nameInputContainer'>
                                    <div>
                                        <p>First Name</p>
                                        <input
                                            className='profileInput'
                                            disabled
                                            id="outlined-basic"
                                            placeholder={user.firstName}
                                        />
                                    </div>
                                    <div className="rightInput">
                                        <p>Last Name</p>
                                        <input
                                            className='profileInput'
                                            disabled
                                            id="outlined-basic"
                                            placeholder={user.lastName}
                                        />
                                    </div>
                                </div>
                                <div className='emailInputContainer'>
                                    <div>
                                        <p>Email</p>
                                        <input
                                            className='profileInput'
                                            disabled
                                            id="outlined-basic"
                                            placeholder={user.email}
                                        />
                                    </div>
                                    <div className="rightInput" >
                                        <p>Username</p>
                                        <input
                                            className='profileInput'
                                            disabled
                                            id="outlined-basic"
                                            placeholder={user.username}
                                        />
                                    </div>
                                </div>
                                <div className='nameInputContainer'>
                                    <div>
                                        <p>Birthdate</p>
                                        <input
                                            className='profileInput'
                                            disabled
                                            id="outlined-basic"
                                            placeholder={moment(user.birthdate).format('L')}
                                        />
                                    </div>
                                    <div className="rightInput" >
                                        <p>Phone Number</p>
                                        <input
                                            className='profileInput'
                                            disabled
                                            id="outlined-basic"
                                            placeholder={user.phone}
                                        />
                                    </div>
                                </div>
                                <div className='walletPassInputContainer'>
                                    <div>
                                        <p>Wallet</p>
                                        <input
                                            className='profileInput'
                                            fullwidth
                                            disabled
                                            id="outlined-basic"
                                            placeholder={user.wallet}
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className='passwordContainer'>
                                        <div>
                                            <p>Password</p>
                                            <input
                                                className="profileInput"
                                                type="text"
                                                id="password"
                                                name="password"
                                                onChange={(e) => setPass(e.target.value)}
                                                value={pass}
                                            />
                                        </div>
                                        <div className='editButtonContainer'>
                                            <Button
                                                className='editButton'
                                                onClick={() => editPassword()}
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Snackbars
                open={successopen}
                text="Password changed"
                severity="success"
                duration={3000}
                close={handleClose} />
        </Container>
    )
}

export default Profile