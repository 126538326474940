import { Chip, Container, Grid } from '@mui/material'

import React, { useEffect, useState } from 'react'
import { getTransactions } from '../../api';
import './styles.css'
import Cookies from 'universal-cookie'
import moment from 'moment/moment';

function Transactions() {
    const cookie = new Cookies()
    const id = cookie.get('id');
    const [transactions, setTransactions] = useState([]);

    useEffect(
        () => {
            getTransactions(id).then(async res => {
                // const transactionResult = [];
                // res.data.forEach(element => {
                //     if (element.status === 0) {
                //         transactionResult.push(element)
                //     }
                // });
                setTransactions(res.data.reverse())
            }).catch(error => console.log(error));
        },
        [])



    return (
        <Container>
            <Grid container fluid >
                <Grid lg={12} md={12} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <div className='transactionsWrapper'>
                        <div className='transactionContainer'>
                            <div className='transactionstTitleContainer'>
                                <p className='transactionsTitle'>Transactions</p>
                            </div>
                            <div className='transactionsContainer'>
                                <table className='transactionsTable'>
                                    <thead>
                                        <tr>
                                            <th align='center' className='tableHeader'><b>From</b></th>
                                            <th className='tableHeader'><b>To</b></th>
                                            <th className='tableHeader'><b>Amount</b></th>
                                            <th className='tableHeader'><b>Currency</b></th>
                                            <th className='tableHeader'><b>Date</b></th>
                                            <th align='center' className='tableHeader'><b>Status</b></th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody'>
                                        {
                                            transactions.map(item => (
                                                <>
                                                    <tr key={item._id} class="tableRow">
                                                        <td align='center'><p>{cookie.get('firstname')}</p></td>

                                                        <td align='center'><p>{item.receiver}</p></td>

                                                        <td align='center'><p>{item.amount}</p></td>
                                                        <td align='center'><p>{item.sender.currency}</p></td>
                                                        <td align='center'><p>{moment(item.createAt).format('L')}</p></td>

                                                        <td align='center'>
                                                            {
                                                                item.status === 0 ?
                                                                    <>
                                                                        <Chip label="Pending" color="warning" />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Chip label="Confirmed" color="success" />
                                                                    </>
                                                            }
                                                        </td>
                                                    </tr>

                                                </>

                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Transactions