import { Container, Grid } from '@mui/material'
import React from 'react'
import './styles.css'
import desktop from '../../assets/images/dashboard.png'
import loss from '../../assets/images/loss.png'
import skillDevelopment from '../../assets/images/skill-development.png'


function HelpBuisness() {

    const helpBuisness = [
        {
            title: 'Increase Campain ROI',
            icon: desktop,
            description: 'To increase your campaign ROI in the cryptocurrency space, it is essential to target a specific audience, use compelling ad creatives that resonate with them.'
        },
        {
            title: 'Reduce Buisness Costs',
            icon: loss,
            description: 'By utilizing a cryptocurrency payment service for your business transactions, you can potentially reduce costs associated with traditional banking fees.'
        },
        {
            title: 'Improve Product Capabilities',
            icon: skillDevelopment,
            description: 'Staying ahead in the cryptocurrency industry requires a commitment to ongoing product development and improvement to enhance capabilities and meet evolving customer needs.'
        },

    ]

    return (
        <div className='helpBuisnessWrapper'>
            <Container fluid>
                <Grid xl={12} lg={12} md={12} sm={12} xs={12} container fluid >
                    <div className='helpBuisnessContainer'>
                        <div className='helpBuisnessTitle'>
                            <p>How can we help your Buisness</p>
                        </div>
                        <div className='helpBuisnessListContainer'>
                            {
                                helpBuisness.map((buisness) => (
                                    <div className='helpBuisnessList'>
                                        <img src={buisness.icon} width={80} height={80} alt={buisness.title} />
                                        <p>{buisness.title}</p>
                                        <span>
                                            {buisness.description}
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </Grid>
            </Container>
        </div>
    )
}

export default HelpBuisness 