import { Container, Grid } from '@mui/material';
import React from 'react';
import Cookies from 'universal-cookie';
import './styles.css';

function Footer() {
    const cookie = new Cookies();

    return (
        <div className='footerWrapper'>
            <Container>
                <Grid container fluid >
                    <Grid lg={12} md={12} sm={12} xs={12} >
                        <div className={cookie.get('token') ? null : 'footerSectionsContainer'}>
                            <div className='footerSectionOneContainer'>
                                <div className='footerLogo'>
                                </div>
                            </div>
                            <hr style={{ backgroundColor: 'white', opacity: '0.32' }}></hr>
                            <div className='footerSectionTwoContainer'>
                                <div className='footerSectionTwoLinks'>
                                    Social Media
                                </div>

                                <div className='footerSectionTwoLinks'>
                                    <div>
                                        <p>© Copy Right-2023 All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default Footer
